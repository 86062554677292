<template>
  <a-form-model
    :model="form"
    ref="form"
    hideRequiredMark
    :class="$style.form"
    validateOnRuleChange
    :rules="rules"
  >
    <a-form-model-item
      :labelCol="{ span: 4 }"
      labelAlign="left"
      :wrapperCol="{ span: 20 }"
      prop="password"
    >
      <template slot="label">
        {{ $t('matterwebset.screenmanage.sharingway') }}
      </template>
      <a-radio-group v-model="setPassword" @change="SetPasswordChange">
        <a-radio :value="0">
          {{ $t('matterwebset.screenmanage.nopassword') }}
        </a-radio>
        <a-radio :value="1">
          {{ $t('matterwebset.screenmanage.setpassword') }}
        </a-radio>
      </a-radio-group>
      <a-input
        v-if="setPassword"
        @change="PasswordChange"
        v-model="form.password"
        placeholder="请设置4位数密码"
        :class="$style.password"
      />
    </a-form-model-item>
    <a-form-model-item
      :labelCol="{ span: 4 }"
      labelAlign="left"
      :wrapperCol="{ span: 20 }"
      prop="effectiveDate"
    >
      <template slot="label">
        {{ $t('matterwebset.screenmanage.linkvalidity') }}
      </template>
      <a-radio-group v-model="form.effectiveDate">
        <a-radio value="FOREVER">
          {{ $t('matterwebset.screenmanage.forever') }}
        </a-radio>
        <a-radio value="SEVEN">
          {{ $t('matterwebset.screenmanage.seven') }}
        </a-radio>
        <a-radio value="ONE">
          {{ $t('matterwebset.screenmanage.one') }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
@Component()
export default class ShareBaseForm extends Vue {
  /**
   * @name 密码锁定只能用字母+数字4位
   */
  PasswordChange({ target: { value } }) {
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.substring(0, 4);
    this.form.password = value;
    this.$refs.form.validateField('password');
  }

  SetPasswordChange({ target: { value } }) {
    if (value === 0) {
      this.$delete(this.form, 'password');
      this.$refs.form.resetFields();
    } else {
      this.$set(this.form, 'password', '');
    }
  }
  setPassword = 0;

  form = {
    effectiveDate: 'SEVEN',
  };

  get rules() {
    const baseRule = { effectiveDate: [{ required: true }] };
    let result = {
      ...baseRule,
    };
    if (this.setPassword === 1) {
      result = {
        ...result,
        password: [
          {
            required: this.setPassword,
            message: '请设置密码',
            trigger: 'change',
          },
          {
            len: 4,
            message: '请设置4位数密码',
            trigger: 'change',
          },
        ],
      };
    }
    return result;
  }

  async getValue() {
    await this.$refs.form.validate();
    return {
      password: this.setPassword ? this.form.password : '',
      effectiveDate: this.form.effectiveDate,
    };
  }
}
</script>
<style lang="less" module>
.password {
  width: 174px;
  margin-left: 20px;
}
</style>
