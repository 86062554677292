<template>
  <div :class="$style.monitorList">
    <div :class="$style.form">
      <a-form-model ref="ruleForm" layout="inline">
        <a-form-model-item>
          <a-input
            style="width: 240px"
            v-model="input"
            @input.stop="() => handleSearch()"
            :placeholder="$t('camera.viewName')"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <div :class="$style.flex">
        <a-button
          v-if="$p.action('READ', '/iot/cameraView')"
          type="primary"
          icon="camera"
          @click="handleWatchView"
          >{{ $t('camera.monitorScreenCode') }}</a-button
        >
      </div>
    </div>
    <a-table
      :columns="columns"
      :dataSource="list"
      :pagination="pagination"
      :class="$style.table"
      @change="handleChange"
    >
      <template slot="action" slot-scope="record">
        <span :class="$style.buttonGroup" @click.stop>
          <router-link
            target="_blank"
            :class="$style.button"
            tag="a"
            :to="'/iot/showCameraView/' + record.pkId"
            >{{ $t('camera.look') }}</router-link
          >
          <a :class="$style.button" @click.stop="handleShare(record)">{{
            $t('iotScreenManage.share')
          }}</a>
          <a
            :class="[$style.button, $style.red]"
            @click.stop="handleDelete(record)"
            >{{ $t('camera.removeCode') }}</a
          >
        </span>
      </template>
    </a-table>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { Component, Vue } from 'vue-property-decorator';
import { debounce } from 'lodash-decorators';
import { createFormModal, createModal } from '@triascloud/x-components';
import ShareForm from './share-form.vue';
import { ShareBaseBox } from '@/components/share-base-form';
import {
  listViewPublic,
  deleteViewPublic,
} from '@/services/monitor/camera-view-list.js';
import Tip from '@/components/tip/index';

@Component()
export default class MonitorList extends Vue {
  input = '';
  @debounce(300)
  handleSearch() {
    this.pagination.current = 1;
    this.getListData();
  }

  async mounted() {
    await this.getListData();
  }

  handleWatchView() {
    const route = this.$router.resolve({
      path: '/iot/cameraView',
    });
    window.open(route.href, '_blank');
  }

  handleShow(record) {
    this.$router.push(`/iot/showCameraView/${record.pkId}`);
  }
  async handleShare(record) {
    const shareInfo = await createFormModal(
      () => <ShareForm name={record.modelName} id={record.pkId} />,
      {
        width: '670px',
        title: this.$t('iotScreenManage.share'),
      },
    );
    const model = await createModal(
      () => (
        <ShareBaseBox
          shareInfo={shareInfo}
          onClose={() => model.handleClosed()}
          info={{
            title: record.modelName,
          }}
        />
      ),
      {
        width: '560px',
        title: this.$t('iotScreenManage.share'),
      },
    );
  }
  async handleDelete(record) {
    await createFormModal(
      () => (
        <Tip>
          <span slot="txt">{this.$t('camera.shareDelTxt')}</span>
          <span slot="subTxt">{this.$t('camera.shareDelSubTxt')}</span>
        </Tip>
      ),
      {
        title: this.$t('camera.operationTips'),
      },
    );
    await deleteViewPublic(record.pkId);
    this.$message.success({
      content: this.$t('camera.removeSuccessCode'),
      duration: 0.2,
      onClose: () => {
        if (this.pagination.current > 1) {
          if (this.list.length === 1) {
            this.pagination.current--;
          }
        } else {
          this.pagination.current = 1;
        }
        this.getListData();
      },
    });
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('camera.order'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('camera.viewName'),
        dataIndex: 'modelName',
      },
      {
        align: 'left',
        title: this.$t('camera.totalCamera'),
        dataIndex: 'cameraNumber',
      },
      {
        align: 'left',
        title: this.$t('camera.creator'),
        dataIndex: 'createdName',
      },
      {
        align: 'left',
        title: this.$t('camera.latestUpdated'),
        dataIndex: 'updatedDate',
        customRender: x => dayjs(x).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: this.$t('iotScreenManage.operation'),
        width: 300,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  list = [];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total =>
      `${this.$t('electricBoxMock.message.total')} ${total} ${this.$t(
        'electricBoxMock.message.record',
      )}`,
  };
  handleChange(pagination) {
    this.pagination.current = pagination.current;
    // TODO 列表数据
    this.getListData();
  }

  async getListData() {
    const { records, total } = await listViewPublic({
      current: this.pagination.current,
      size: this.pagination.pageSize,
      modelName: this.input,
    });
    this.pagination.total = +total;
    this.list = records;
  }
}
</script>
<style lang="less" module>
.monitorList {
  .form {
    padding: 23px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    :global(.ant-form) {
      display: flex;
      align-items: center;
    }
    .btn {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .buttonGroup {
    .red {
      color: #f72f2f;
    }
    .button {
      padding-right: 30px;
      position: relative;
    }
    .button:last-of-type {
      &::after {
        width: 0;
      }
    }
  }
}
</style>
