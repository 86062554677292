<template>
  <div :class="$style.detail">
    <div :class="$style.info">
      <div :class="$style['info-item-wrap']">
        <info-item
          :class="$style.infoItem"
          :name="`${$t('camera.deviceCode')}：`"
          :value="detail.deviceSerial"
        />
        <info-item
          :class="$style.infoItem"
          :name="`${$t('camera.deviceSecret')}：`"
          :value="detail.validateCode"
        />
      </div>
      <div :class="$style['info-item-wrap']">
        <info-item
          :class="$style.infoItem"
          :name="`${$t('camera.networkStatus')}：`"
          :value="detail.cameraStatus === 'ONLINE' ? '在线' : '离线'"
        />
        <info-item
          v-if="detail.bindStatus !== 'UN_BIND'"
          :class="$style.infoItem"
          :name="`${$t('camera.latestlaunchTime')}：`"
          :value="detail.lastOnlineTime"
        />
        <info-item
          v-else
          :class="$style.infoItem"
          :name="`${$t('camera.addPeople')}：`"
          :value="detail.bindMemberName"
        />
      </div>
    </div>
    <div :class="$style.contentBox">
      <a-tabs default-active-key="1" :tabBarStyle="{ marginLeft: '20px' }">
        <a-tab-pane key="1" :tab="$t('camera.bindRecords')">
          <bind-record :cameraId="detail.id" />
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('camera.warningRecord')" force-render>
          <EarlyWarning :cameraId="detail.id" :detail="data" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <div :class="$style.buttonGroup">
      <a-button @click="close">关闭</a-button>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import InfoItem from '../info-item';
import BindRecord from './bind-record';
import EarlyWarning from './earlyWarning';
import { getRelatedProject } from '@/services/things/project';

@Component({
  components: {
    InfoItem,
    BindRecord,
    EarlyWarning,
  },
})
export default class ElectricBoxDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) detail;
  @Prop({ type: Object, default: () => {} }) data;
  @Prop({ type: Function, default: function() {} }) close;

  hasProjected = false;

  async mounted() {
    this.hasProjected = await this.getRelatedProjectApi();
  }

  async getRelatedProjectApi() {
    try {
      const result = await getRelatedProject();
      return !!result.length;
    } catch (error) {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.detail {
  padding-bottom: 0;
  padding-right: 0;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  .info {
    margin: 20px;
    padding: 0 20px;
    border-radius: 6px;
    box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);

    .infoItem {
      padding: 15px 0;
    }
  }
  .info-item-wrap {
    display: flex;
  }

  .contentBox {
    .boxTitle {
      padding: 0 20px;
      font-size: 14px;
      color: var(--font);
      padding-bottom: 10px;
    }
    .oBtn {
      margin-right: 20px;
    }
  }

  .buttonGroup {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 80px;
    line-height: 80px;
    background-color: var(--modal-bg);
    box-sizing: content-box;
    :global(.ant-btn) {
      margin-left: 20px;
    }
  }
}
</style>
