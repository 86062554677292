import request from '../request';
const serviceName = '/monitor/api';
const pcServiceName = '/monitor/client/camera';

/**
 * @name GET获取实时监控视频-new
 * @param { String } buildingId
 */
export function getPreview(buildingId) {
  return request(`${serviceName}/cameraBind/getPreview/${buildingId}`, {
    method: 'GET',
    auth: false,
  });
}

/**
 * @name 摄像头绑定
 */
export function bind(data) {
  return request(`${serviceName}/cameraBind`, {
    method: 'POST',
    body: data,
  });
}
/**
 * @name 摄像头批量绑定
 */
export function batchBind(data) {
  return request(`${serviceName}/cameraBind/batch`, {
    method: 'POST',
    body: data,
  });
}

// GET获取实时监控截图 new
export function getCarouselScreenshot(id) {
  return request(`${serviceName}/cameraBind/getCarouselScreenshot/${id}`, {
    method: 'GET',
    auth: false,
    closeMessage: true,
  });
}

// GET 获取7日监控截图
export function getScreenshotBySevenDays(data) {
  return request(`${serviceName}/cameraBind/screenshot`, {
    body: data,
    closeMessage: true,
    auth: false,
  });
}

// 摄像头列表
export function getList(data) {
  return request(`${pcServiceName}/list`, {
    method: 'POST',
    body: data,
  });
}

// 摄像头数量
export function getCameraStatistics(data) {
  return request(`${pcServiceName}/count`, {
    method: 'GET',
    body: data,
  });
}

// 摄像头解绑
export function unBindCamera(data) {
  return request(`${pcServiceName}/unBind`, {
    method: 'POST',
    body: data,
  });
}

// 摄像头移除
export function removeCamera(data) {
  return request(`${pcServiceName}/remove`, {
    method: 'POST',
    body: data,
  });
}

// 添加摄像头
export function addCamera(data) {
  return request(`${pcServiceName}/add`, {
    method: 'POST',
    body: data,
  });
}

// 编辑摄像头
export function editCamera(data) {
  return request(`${pcServiceName}/compiler`, {
    method: 'POST',
    body: data,
  });
}

// 绑定记录列表
export function cameraBindRecord(data) {
  return request(`${pcServiceName}/cameraBindRecord`, {
    method: 'POST',
    body: data,
  });
}

// 预警记录列表
export function cameraWarnRecord(data) {
  return request(`${pcServiceName}/warn`, {
    method: 'POST',
    body: data,
  });
}

// 重新绑定摄像头
export function cameraRebind(data) {
  return request(`${pcServiceName}/rebind`, {
    method: 'PUT',
    body: data,
  });
}
