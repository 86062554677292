<template>
  <div :class="$style.itemBox">
    <div v-if="item.deleted === false">
      <span :class="$style.author" v-if="item.deleted === false">{{
        $t('camera.current')
      }}</span>
    </div>
    <div :class="$style.iconBack">
      <x-icon type="tc-icon-video-camera" :class="$style.icon" />
    </div>
    <div style="margin: 20px 0 20px 20px">
      <div>
        <span :class="$style.title">{{ $t('camera.bindProject') }}：</span>
        <span>{{ item.projectName }}</span>
      </div>
      <div>
        <span :class="$style.title">{{ $t('camera.bindBuilding') }}：</span>
        <span>{{ item.buildNumber }}</span>
      </div>
      <div>
        <span :class="$style.title">{{ $t('camera.bindTime') }}：</span>
        <span>{{ formatTime(item.cameraBindTime) }}</span>
      </div>
      <div>
        <span :class="$style.title">{{ $t('camera.bindPerson') }}：</span>
        <span>{{ isDefault(item.creatorName) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CardIcon extends Vue {
  @Prop({ type: Boolean, default: false }) author;
  @Prop({ type: String, default: 'TC tc-icon-xiangmu2' }) iconClassName;
  @Prop({ type: Object, default: () => {} }) item;

  isDefault(value) {
    if (!value) return this.$t('camera.admin');
    else return value;
  }

  formatTime(value) {
    if (value) return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    else return value;
  }
}
</script>
<style lang="less" module>
.itemBox {
  display: flex;
  margin: 5px 0 15px;
  box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);
  border-radius: 6px;
  padding: 0 20px;
  align-items: center;
  position: relative;
  .iconBack {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: var(--tag);
    .icon {
      flex-basis: 38px;
      width: 38px;
      height: 38px;
      font-size: 38px;
      margin-right: 9px;
      display: flex;
      position: relative;
      color: var(--primary);
      &:before {
        display: flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }
  }
  .author {
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 10px;
    left: 0;
    padding: 4px 9px;
    border-radius: 0 12px 12px 0;
    background-color: var(--primary);
  }
  .title {
    color: var(--font-info);
  }
}
</style>
