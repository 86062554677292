<template>
  <a-form-model
    class="addCameraBoxwrapper"
    layout="vertical"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <a-form-model-item :label="$t('camera.deviceCode')" prop="deviceSerial">
      <a-input
        :placeholder="
          $t('electricBox.form.enter', { value: $t('camera.deviceCode') })
        "
        :disabled="addOrEditStatus"
        v-model="form.deviceSerial"
      />
    </a-form-model-item>
    <a-form-model-item :label="$t('camera.deviceSecret')" prop="validateCode">
      <a-input
        :placeholder="
          $t('electricBox.form.enter', { value: $t('camera.deviceSecret') })
        "
        :disabled="addOrEditStatus"
        v-model="form.validateCode"
      />
    </a-form-model-item>
    <a-form-model-item :label="$t('camera.cameraName')" prop="cameraName">
      <a-input
        :placeholder="
          $t('electricBox.form.enter', { value: $t('camera.cameraName') })
        "
        v-model="form.cameraName"
      />
    </a-form-model-item>
    <a-form-model-item
      :label="$t('electricBoxMock.info.projectName')"
      prop="projectId"
    >
      <a-select
        show-search
        allowClear
        :placeholder="$t('electricBox.select.projects')"
        v-model="form.projectId"
        :disabled="statusDisabled"
        optionFilterProp="children"
        :filter-option="false"
        @search="input => filterProjectOption(input)"
        @change="handleProjectChange"
      >
        <a-select-option
          :value="item.id"
          v-for="item in projects"
          :key="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <a-form-model-item
      :label="$t('electricBox.form.buildingNumber')"
      prop="buildId"
    >
      <a-select
        v-model="form.buildId"
        :disabled="statusDisabled"
        :placeholder="$t('electricBox.form.buildingDesc')"
        @change="handleBuildChange"
        option-filter-prop="children"
      >
        <a-select-option
          :value="item.id"
          v-for="item in buildings"
          :key="item.id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-model-item>
    <div class="operationTips">
      {{ $t('camera.cameraTip') }}
    </div>
  </a-form-model>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  getRelatedProject,
  getProjectAndBuildingInAuthority as getProjectAndBuilding,
} from '@/services/things/project';
import { addCamera, editCamera } from '@/services/monitor/camera-bind.js';
import { Debounce } from 'lodash-decorators';
@Component({})
export default class AddElectricBox extends Vue {
  @Prop({ type: Object, default: () => {} }) editData;
  @Prop({ type: String, default: '' }) editFlags;

  form = {
    deviceSerial: '',
    validateCode: '',
    cameraName: 'camera01',
    projectId: '',
    buildId: '',
    cameraId: '',
  };
  rules = {
    deviceSerial: [
      {
        required: true,
        message: '设备码不能为空',
      },
    ],
    validateCode: [
      {
        required: true,
        message: '验证码不能为空',
      },
    ],
    cameraName: [
      {
        max: 12,
        message: '请设置小于12个字符的名称',
        trigger: 'change',
      },
    ],
  };

  resolve = null;
  reject = null;
  getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.form.deviceSerial = this.formatTrim(this.form.deviceSerial);
            this.form.validateCode = this.formatTrim(this.form.validateCode);
            this.form.cameraName = this.formatTrim(this.form.cameraName);
            const params = {
              deviceSerial: this.form.deviceSerial,
              validateCode: this.form.validateCode,
              cameraName: this.form.cameraName,
              projectId: this.form.projectId,
              buildId: this.form.buildId,
            };
            if (this.form.cameraId) {
              params.cameraId = this.form.cameraId;
            }
            this.editFlags === 'add'
              ? await addCamera([params])
              : await editCamera(params);
            this.$message.success('绑定成功');
            resolve(true);
          } catch (error) {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }

  formatTrim(value = '') {
    return value.trim();
  }

  mounted() {
    this.initData();
  }
  projects = [];
  cameraStatus = '';
  get statusDisabled() {
    return ['ONLINE', 'OFFLINE'].indexOf(this.cameraStatus) > -1;
  }
  get addOrEditStatus() {
    return this.editFlags === 'edit';
  }
  async initData() {
    const data = this.editData;
    this.form.deviceSerial = data.deviceSerial;
    this.form.validateCode = data.validateCode;
    this.form.cameraName = data.cameraName || 'camera01';
    this.form.projectId = data.projectId || undefined;
    this.projectSearchInput = data.projectName;
    this.form.cameraId = data.cameraId;
    this.cameraStatus = data.cameraStatus;
    await this.handleProjectChange(this.form.projectId);
    this.form.buildId = data.buildId || undefined;
    this.getRelatedProjectApi();
  }
  async getRelatedProjectApi() {
    const params = {
      keyword: this.projectSearchInput ? this.projectSearchInput : undefined,
    };
    const result = await getRelatedProject(params);
    this.projects = result.map(item => {
      return {
        id: item.projectId,
        name: item.projectName,
      };
    });
  }
  async handleProjectChange(id) {
    this.form.projectId = id;
    this.buildings = [];
    this.form.buildId = undefined;
    if (!id) {
      this.projectSearchInput = '';
      await this.getRelatedProjectApi();
    }
    this.getProjectAndBuildingApi();
  }
  projectSearchInput = '';
  @Debounce(300)
  filterProjectOption(input) {
    this.projectSearchInput = input.toLowerCase();
    this.getRelatedProjectApi();
  }
  buildings = [];
  async getProjectAndBuildingApi() {
    if (this.form.projectId) {
      const result = await getProjectAndBuilding(this.form.projectId);
      this.buildings = result.buildings.map(item => {
        return {
          id: item.pkBuildingId,
          name: item.buildingName,
        };
      });
    }
  }
  handleBuildChange(id) {
    this.form.buildId = id;
  }
}
</script>

<style lang="less" scoped>
.operationTips {
  color: #999;
  font-size: 12px;
}
</style>
