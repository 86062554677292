<template>
  <div :class="$style.deviceList">
    <div :class="$style.form">
      <a-form-model ref="ruleForm" layout="inline">
        <a-form-model-item>
          <a-input
            style="width: 240px"
            v-model="input"
            @input.stop="() => handleSearch()"
            :placeholder="$t('camera.codeAndName')"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 110px"
            v-model="networkStatus"
            :getPopupContainer="t => t.parentNode"
            @change="handleNetworkStatusChange"
          >
            <a-select-option value>{{
              $t('iotScreenManage.all')
            }}</a-select-option>
            <a-select-option value="ONLINE">{{
              $t('camera.onlineCode')
            }}</a-select-option>
            <a-select-option value="OFFLINE">{{
              $t('camera.offlineCode')
            }}</a-select-option>
            <a-select-option value="UN_BIND">{{
              $t('camera.unbind')
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <div :class="$style.flex">
        <a-button
          type="primary"
          icon="plus"
          @click="addCameraBoxHandle({}, 'add')"
          v-if="hasProjected && $p.action('CREATE')"
          >{{ $t('camera.addCamera') }}</a-button
        >
      </div>
    </div>
    <a-table
      :columns="columns"
      :dataSource="list"
      :pagination="pagination"
      :class="$style.table"
      :scroll="{ x: 1600 }"
      @change="handleChange"
      :customRow="customRow"
    >
      <template slot="action" slot-scope="record">
        <span :class="$style.buttonGroup" @click.stop>
          <a
            v-if="notTobeBound(record)"
            :class="$style.button"
            @click.stop="openMonitor(record)"
            >{{ $t('camera.monitor') }}</a
          >
          <a
            v-if="$p.action('UPDATE')"
            :class="$style.button"
            @click.stop="addCameraBoxHandle(record, 'edit')"
            >{{ $t('camera.edit') }}</a
          >
          <a
            v-if="isTobeBound(record) && $p.action('UPDATE')"
            :class="[$style.button, $style.red]"
            @click.stop="handleUnbind(record, 'remove')"
            >{{ $t('camera.remove') }}</a
          >
          <a
            :class="[$style.button, $style.red]"
            @click.stop="handleUnbind(record, 'relieve')"
            v-if="notTobeBound(record) && $p.action('UPDATE')"
            >{{ $t('camera.unbindCode') }}</a
          >
        </span>
      </template>
      <template slot="validatecode" slot-scope="text, record">
        <div>
          <span>{{ text }}</span>
          <x-icon
            type="tc-icon-copy"
            :class="$style.copy"
            @click.stop="copy(record)"
          />
        </div>
      </template>
      <template slot="cameraStatus" slot-scope="text, record">
        <span v-if="text === 'ONLINE'" :class="$style.statusOnline">{{
          $t('camera.onlineCode')
        }}</span>
        <span v-if="text === 'OFFLINE'" :class="$style.statusOffline">{{
          $t('camera.offlineCode')
        }}</span>
        <span v-if="text === 'UN_BIND'" :class="$style.statusTobeBinding">{{
          $t('camera.unbind')
        }}</span>
        <x-icon
          v-if="text === 'UN_BIND' && isRefresh(record)"
          type="tc-icon-recovery"
          :class="$style.statusTobeBinding"
          @click.stop="reBind(record)"
        />
      </template>
    </a-table>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { clipboardWrite, delay } from '@triascloud/utils';
import {
  createFormModal,
  createDrawer,
  createModal,
} from '@triascloud/x-components';
import Tip from '@/components/tip/index';
import { getPreview } from '@/services/monitor/api';
import {
  getList,
  unBindCamera,
  removeCamera,
  cameraRebind,
} from '@/services/monitor/camera-bind.js';
import { getRelatedProject } from '@/services/things/project';
import { debounce } from 'lodash-decorators';
import AddCameraBox from '../components/add-camera-box.vue';
import Detail from '../components/detail/index.vue';
import Monitor from '@/components/monitor';

@Component()
export default class DeviceList extends Vue {
  async getRelatedProjectApi() {
    try {
      const result = await getRelatedProject();
      return !!result.length;
    } catch (error) {
      return false;
    }
  }
  isRefresh(item) {
    return !!item.buildId;
  }
  async reBind(item) {
    const id = item.cameraId;
    const formData = new FormData();
    formData.append('cameraId', id);
    try {
      await cameraRebind(formData);
      await delay(500);
      await this.getListApi();
    } catch {
      return false;
    }
  }
  notTobeBound(item) {
    return item.cameraStatus !== 'UN_BIND';
  }
  isTobeBound(item) {
    return item.cameraStatus === 'UN_BIND';
  }
  handleUnbind(item, flag) {
    this.unbindBuilding(item.cameraId, flag);
  }
  async unbindBuilding(id, flag) {
    const text =
      flag === 'remove'
        ? this.$t('camera.removeText')
        : this.$t('camera.unbindText');
    const tips =
      flag === 'remove'
        ? this.$t('camera.removeTip')
        : this.$t('camera.unbindTip');
    const confirmBtnTitle =
      flag === 'remove'
        ? this.$t('camera.removeCamera')
        : this.$t('camera.unbindCode');
    await createFormModal(
      () => (
        <Tip>
          <template slot="txt">{text}</template>
          <span slot="subTxt" style="color: #F72F2F">
            {tips}
          </span>
        </Tip>
      ),
      {
        width: '442px',
        title: confirmBtnTitle,
        onOk: () => this.socketUnbind(id, flag),
      },
    );
  }
  async socketUnbind(id, flag) {
    try {
      if (flag !== 'remove') {
        await unBindCamera([id]);
        this.$message.success(this.$t('camera.unbindSuccess'));
        await delay(500);
        await this.getListApi();
      } else {
        await removeCamera([id]);
        if (this.pagination.current > 1) {
          if (this.list.length === 1) {
            this.pagination.current--;
          }
        } else {
          this.pagination.current = 1;
        }
        await delay(500);
        await this.getListApi();
        this.$message.success(this.$t('camera.removeSuccess'));
      }
    } catch (error) {
      this.$message.error(error.message);
    }
  }

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('camera.order'),
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('camera.deviceCode'),
        dataIndex: 'deviceSerial',
      },
      {
        align: 'left',
        title: this.$t('camera.deviceSecret'),
        dataIndex: 'validateCode',
        scopedSlots: { customRender: 'validatecode' },
      },
      {
        title: this.$t('camera.status'),
        dataIndex: 'cameraStatus',
        scopedSlots: { customRender: 'cameraStatus' },
      },
      {
        title: this.$t('camera.projectBinded'),
        dataIndex: 'projectName',
        ellipsis: true,
        width: 300,
      },
      {
        title: this.$t('camera.buildingBinded'),
        dataIndex: 'buildingName',
      },
      {
        align: 'left',
        title: this.$t('camera.nickname'),
        dataIndex: 'cameraName',
        ellipsis: true,
        width: 200,
      },
      {
        title: this.$t('iotScreenManage.operation'),
        width: 300,
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  customRow(record) {
    return {
      on: {
        click: () => {
          this.openDetail(record);
        },
      },
    };
  }
  modalDrawer = undefined;
  async openDetail(item) {
    const result = {
      id: item.cameraId,
      deviceSerial: item.deviceSerial,
      validateCode: item.validateCode,
      cameraStatus: item.cameraStatus,
      bindStatus: item.bindStatus,
    };
    if (item.bindStatus === 'UN_BIND') {
      result.bindMemberName = item.bindMemberName;
    } else {
      result.lastOnlineTime =
        item.lastOnlineTime &&
        dayjs(item.lastOnlineTime).format('YYYY-MM-DD HH:mm:ss');
    }
    if (result) {
      this.modalDrawer = createDrawer(
        () => (
          <Detail
            data={item}
            detail={result}
            close={this.handleCloseModalDrawer}
          />
        ),
        {
          title: this.$t('camera.cameraDetails'),
          width: '690px',
          wrapClassName: 'camera-box-list',
        },
      );
    }
  }
  handleCloseModalDrawer() {
    this.modalDrawer.handleClosed();
  }
  @debounce(300)
  async openMonitor(record) {
    const data = await getPreview(record.cameraId);
    // const baseUrl = 'https://open.ys7.com/ezopen/h5/iframe_se?autoplay=1';
    // let monitorUrl = '';
    // if (data.online) {
    //   monitorUrl = `${baseUrl}&url=${data.url}&accessToken=${data.token}`;
    // }
    const result = {
      ...record,
      lastOnlineTime: data.lastOnlineTime
        ? dayjs(data.lastOnlineTime).format('YYYY/MM/DD HH:mm')
        : '',
    };
    createModal(
      () => <Monitor url={data.url} token={data.token} result={result} />,
      {
        title: this.$t('camera.monitoring'),
        width: 840,
        height: 500,
        className: this.$style['camera-modal'],
      },
    );
  }

  hasProjected = false;
  async mounted() {
    await this.getListApi();
    this.hasProjected = await this.getRelatedProjectApi();
  }

  input = '';
  networkStatus = '';
  @debounce(300)
  handleSearch() {
    this.pagination.current = 1;
    this.getListApi();
  }
  handleNetworkStatusChange() {
    this.pagination.current = 1;
    this.getListApi();
  }

  list = [];
  async getListApi() {
    try {
      const params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      if (this.networkStatus) {
        const statusMap = {
          ONLINE: 'cameraStatus',
          OFFLINE: 'cameraStatus',
          BIND: 'bindStatus',
          UN_BIND: 'bindStatus',
        };
        params[statusMap[this.networkStatus]] = this.networkStatus;
      }
      if (this.input) {
        params.input = this.input;
      }
      const { records, current, size, total } = await getList(params);
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.list = records;
    } catch (error) {
      this.$message.error(error.message);
    }
  }
  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total =>
      `${this.$t('electricBoxMock.message.total')} ${total} ${this.$t(
        'electricBoxMock.message.record',
      )}`,
  };
  handleChange(pagination) {
    this.pagination = pagination;
    this.getListApi();
  }

  async addCameraBoxHandle(obj, flag) {
    try {
      const title =
        flag === 'add'
          ? this.$t('camera.addCamera')
          : this.$t('camera.editCamera');
      const result = await createFormModal(
        () => <AddCameraBox editData={obj} editFlags={flag} />,
        {
          width: '442px',
          title: title,
        },
      );
      if (result) {
        await delay(500);
        await this.getListApi();
      }
    } catch (error) {
      if (error.message !== 'user cancel') this.$message.error(error.message);
    }
  }
  async copy(item) {
    const str = `设备码：${item.deviceSerial}\n验证码：${item.validateCode}\n已绑定项目：${item.projectName}\n已绑定楼栋：${item.buildingName}`;
    await clipboardWrite(str);
    this.$message.success(this.$t('camera.replicatingSuccess'));
    return item.text;
  }
}
</script>
<style lang="less" module>
.deviceList {
  :global {
    .ant-table-wrapper .ant-spin-container .ant-table-body,
    .ant-table-wrapper .ant-table-scroll .ant-table-body {
      flex: 1;
      overflow: auto !important;
      max-height: none !important;
    }
  }
  .statusOnline {
    color: #41c400;
  }
  .statusOffline {
    color: var(--delete);
  }
  .statusTobeBinding {
    color: #ff9900;
  }
}

.copy {
  margin-left: 10px;
  color: var(--primary);
  cursor: pointer;
}
.form {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global(.ant-form) {
    display: flex;
    align-items: center;
  }
  .btn {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
:global(.camera-box-list .ant-drawer-body) {
  padding: 0;
  overflow-y: hidden;
}
.buttonGroup {
  .red {
    color: #f72f2f;
  }
  .button {
    padding-right: 30px;
    position: relative;
  }
  .button:last-of-type {
    &::after {
      width: 0;
    }
  }
}
.camera-modal {
  :global {
    .ant-modal-body {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
