<template>
  <div :class="$style.itemBox">
    <div :class="$style.iconBack">
      <x-icon type="tc-icon-speaker" :class="$style.icon" />
    </div>
    <div style="margin: 20px 0 20px 20px">
      <div>
        <span :class="$style.title">{{ $t('camera.warningTime') }}：</span>
        <span>{{ formatTime(item.createdDate) }}</span>
      </div>
      <div>
        <span :class="$style.title">{{ $t('camera.warningPersonnel') }}：</span>
        <span>{{ isDefault(item.memberName) }}</span>
      </div>
      <div style="display: flex">
        <div :class="$style.title">{{ $t('camera.warningContent') }}：</div>
        <div>
          <div>
            <span :class="$style.content">
              {{ item.content && formatStr(item.content) }}
            </span>
            <span
              v-if="showExpandBtn"
              :class="$style.showExpand"
              @click.stop="showExpandDetail(item.content)"
              >{{ $t('camera.expandDetails') }}</span
            >
          </div>
          <div v-if="showWarningImage" :class="$style.detailBox">
            <img
              :class="$style.image"
              @click.stop="$preview(pictureUrl, 'image')"
              :src="pictureUrl"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-if="showWarningImage" :class="$style.toMonitor">
        <div :class="$style.lookMonitor">
          <span @click.stop="openMonitor">{{ $t('camera.monitor') }}</span>
          <span :class="$style.toMonitorIcon"> &gt; </span>
        </div>
        <div :class="$style.hide">
          <span @click.stop="hideExpanDetail(item.content)">{{
            $t('camera.expandDetails')
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import { debounce } from 'lodash-decorators';
import { getPreview } from '@/services/monitor/api';
import { getBackendAuth } from '@/services/oss/oss';
import Monitor from '@/components/monitor';

@Component({})
export default class CardIcon extends Vue {
  @Prop({ type: Boolean, default: false }) author;
  @Prop({ type: String, default: 'TC tc-icon-xiangmu2' }) iconClassName;
  @Prop({ type: Object, default: () => {} }) item;
  @Prop({ type: Object, default: () => {} }) detail;

  isDefault(value) {
    if (!value) return this.$t('camera.admin');
    else return value;
  }

  @debounce(300)
  async openMonitor() {
    const data = await getPreview(this.detail.cameraId);
    const baseUrl = 'https://open.ys7.com/ezopen/h5/iframe_se?autoplay=1';
    let monitorUrl = '';
    if (data.online) {
      monitorUrl = `${baseUrl}&url=${data.url}&accessToken=${data.token}`;
    }
    const result = {
      ...this.detail,
      lastOnlineTime: data.lastOnlineTime
        ? dayjs(data.lastOnlineTime).format('YYYY/MM/DD HH:mm')
        : '',
    };
    createModal(() => <Monitor url={monitorUrl} result={result} />, {
      title: this.$t('camera.monitoring'),
      width: 840,
      height: 500,
      className: this.$style['camera-modal'],
    });
  }

  @Watch('item', { immediate: true })
  onChangeItem() {
    this.formatPictureUrl();
  }

  pictureUrl = '';
  async formatPictureUrl() {
    try {
      if (this.item.screenshotUrl.indexOf('oss') > -1) {
        const list = await getBackendAuth([this.item.screenshotUrl]);
        this.pictureUrl = list[0];
      } else {
        this.pictureUrl = this.item.screenshotUrl;
      }
    } catch (error) {
      this.pictureUrl = '';
    }
  }

  formatTime(value) {
    if (value) return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    else return value;
  }
  showWarningImage = false;
  formatStr(s) {
    if (this.showWarningImage) {
      return s;
    } else {
      if (s.length > 45) {
        return s.slice(0, 60) + '...';
      } else {
        return s;
      }
    }
  }
  get showExpandBtn() {
    return !this.showWarningImage;
  }
  showExpandDetail(str) {
    this.showWarningImage = true;
    this.formatStr(str);
  }
  hideExpanDetail(str) {
    this.showWarningImage = false;
    this.formatStr(str);
  }
}
</script>
<style lang="less" module>
.camera-modal {
  :global {
    .ant-modal-body {
      margin: 0;
      padding: 0;
    }
  }
}
.itemBox {
  display: flex;
  margin: 5px 0 15px;
  box-shadow: 0 0 6px 1px rgba(144, 144, 144, 0.3);
  border-radius: 6px;
  padding: 0 20px;
  align-items: flex-start;
  position: relative;
  .iconBack {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #ffeded;
    margin-top: 33px;
    .icon {
      flex-basis: 38px;
      width: 38px;
      height: 38px;
      font-size: 38px;
      margin-right: 9px;
      display: flex;
      position: relative;
      color: #fe5959;
      &:before {
        display: flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    }
  }
  .title {
    color: var(--font-info);
    width: 70px;
    flex: 0 0 70px;
  }
  .content {
    word-break: break-all;
  }
  .showExpand {
    color: #4360ef;
    cursor: pointer;
    margin-left: 5px;
  }
  .detailBox {
    animation: showImage;
    margin-top: 5px;
    .image {
      width: 224px;
    }
  }
  .toMonitor {
    padding-top: 10px;
    color: #4360ef;
    .lookMonitor {
      position: absolute;
      left: 150px;
      bottom: 10px;
      cursor: pointer;
    }
    .toMonitorIcon {
      cursor: pointer;
    }
    .hide {
      position: absolute;
      right: 20px;
      bottom: 10px;
      cursor: pointer;
      color: var(--font-info);
    }
  }
}
</style>
