<template>
  <div :class="$style.listWrap">
    <div v-if="recordList.length > 0">
      <x-scroll-view
        :class="$style.imageContent"
        class="clearfix"
        :disabled="test"
        :lowerThreshold="80"
        :immediateCheck="false"
        @scrolltolower="getList()"
      >
        <warningItem
          v-for="item in recordList"
          :key="item.id"
          :item="item"
          :detail="detail"
        />
      </x-scroll-view>
    </div>
    <div v-else :class="$style.empty">
      <x-icon :class="$style.emptyIcon" type="tc-icon-empty-box"></x-icon>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import warningItem from './components/warning-item';
import { cameraWarnRecord } from '@/services/monitor/camera-bind.js';

@Component({
  components: {
    warningItem,
  },
})
export default class EarlyWarning extends Vue {
  @Prop({ type: Number, default: 0 }) cameraId;
  @Prop({ type: Object, default: () => {} }) detail;

  mounted() {
    this.currentPage = 1;
    this.isEnd = false;
    this.getList();
  }

  get test() {
    return this.isEnd;
  }

  recordList = [];
  isEnd = false;
  currentPage = 1;
  async getList() {
    const params = {
      cameraId: this.cameraId,
      pageNum: this.currentPage,
      pageSize: 10,
    };
    const { records } = await cameraWarnRecord(params);
    this.recordList = [...this.recordList, ...records];
    if (records.length) {
      this.currentPage += 1;
    } else {
      this.isEnd = true;
    }
  }
}
</script>
<style lang="less" module>
.listWrap {
  padding: 0 15px;
  .imageContent {
    overflow-y: auto;
    padding: 0 5px 80px;
    height: calc(100vh - 105px - 130px);
  }
  .empty {
    text-align: center;
    margin-top: 20px;
    .emptyIcon {
      font-size: 120px;
      color: var(--primary);
    }
  }
}
</style>
