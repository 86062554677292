<template>
  <div :class="$style.wrap">
    <div :class="$style.height">您将分享&nbsp;&nbsp;{{ name }}</div>
    <share-base-form ref="shareForm" @form-validate="handeFormValidate" />
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ShareBaseForm } from '@/components/share-base-form';
import { shareCameraView } from '@/services/monitor/camera-view-list.js';
@Component({
  components: {
    ShareBaseForm,
  },
})
export default class ShareForm extends Vue {
  @Prop({ type: String, default: '' }) name;
  @Prop({ type: String, default: '' }) id;

  baseFormBoolean = false;
  handeFormValidate(flag) {
    this.baseFormBoolean = flag;
  }

  async getValue() {
    const baseForm = await this.$refs.shareForm.getValue();

    const data = await shareCameraView({
      password: baseForm.password,
      shareExpireType: baseForm.effectiveDate,
      viewPublicId: this.id,
    });

    return {
      id: data,
      password: baseForm.password,
      shareUrl: `${window.location.origin}/iot/generalScreen/monitorProof/${data}`,
    };
  }
}
</script>
<style lang="less" module>
.wrap {
  .height {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
</style>
