<template>
  <div :class="$style.manageBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">{{
          $t('enterpriseManagement.camera.title')
        }}</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <div :class="$style.topData">
          <div
            :class="$style.item"
            v-for="(item, idx) in topDataOptions"
            :key="idx"
            :style="{ backgroundColor: item.backgroundColor }"
          >
            <div :class="$style.textDiv">
              <div :class="$style.numberDiv">
                {{ countList[item.number] }}
              </div>
              <div :class="$style.numberTextDiv">
                {{ item.text }}
              </div>
            </div>
            <div :class="$style.iconDiv" :style="{ boxShadow: item.boxShadow }">
              <x-icon
                :class="$style.iconStyle"
                :type="item.icon"
                :style="{ color: item.backgroundColor }"
              />
            </div>
          </div>
        </div>
        <a-tabs
          :default-active-key="activeKey"
          :tabBarStyle="{ marginLeft: '8px' }"
          @change="changeTab"
        >
          <a-tab-pane key="device-list" :tab="$t('camera.deviceList')">
            <device-list v-if="activeKey === 'device-list'" />
          </a-tab-pane>
          <a-tab-pane key="monitor-list" :tab="$t('camera.monitorScreen')">
            <monitor-list v-if="activeKey === 'monitor-list'" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { getCameraStatistics } from '@/services/monitor/camera-bind.js';
import { LayoutContent } from '@triascloud/x-blocks';
import DeviceList from './device-list/index.vue';
import MonitorList from './monitor-list/index.vue';

@Component({
  components: {
    LayoutContent,
    DeviceList,
    MonitorList,
  },
})
export default class CameraManage extends Vue {
  topDataOptions = [
    {
      number: 'totalNumber',
      text: this.$t('camera.totalDevice'),
      icon: 'tc-icon-device',
      backgroundColor: '#947dfa',
      boxShadow: '-6px 0 0 4px #c9befc',
      value: '',
    },
    {
      number: 'onlineNumber',
      text: this.$t('camera.online'),
      icon: 'tc-icon-device-online',
      backgroundColor: '#00b893',
      boxShadow: '-6px 0 0 4px #80dbc9',
      value: 'ONLINE',
    },
    {
      number: 'offlineNumber',
      text: this.$t('camera.offline'),
      icon: 'tc-icon-device-offline',
      backgroundColor: '#fe5959',
      boxShadow: '-6px 0 0 4px #feacac',
      value: 'OFFLINE',
    },
    {
      number: 'unBindNumber',
      text: this.$t('camera.unbind'),
      icon: 'tc-icon-device-warning',
      backgroundColor: '#ffa348',
      boxShadow: '-6px 0 0 4px #ffd1a3',
      value: 'UN_BIND',
    },
  ];

  countList = {
    totalNumber: 0,
    onlineNumber: 0,
    offlineNumber: 0,
    bindNumber: 0,
    unBindNumber: 0,
  };
  async getCount() {
    try {
      this.countList = await getCameraStatistics();
    } catch {
      return false;
    }
  }

  activeKey = this.$route.query.active || 'device-list';
  changeTab(activeKey) {
    this.activeKey = activeKey;
    this.$router.push({ query: { active: activeKey } });
  }
  async mounted() {
    await this.getCount();
  }
}
</script>
<style lang="less" module>
.manageBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }

  .topData {
    display: flex;
    justify-content: space-between;
    .item {
      width: 23%;
      height: 116px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .textDiv {
        color: #fff;
        text-align: center;
        width: 70%;
        .numberDiv {
          font-weight: 500;
          font-size: 32px;
        }
        .numberTextDiv {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .iconDiv {
        position: absolute;
        right: 15px;
        background-color: white;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconStyle {
          font-size: 50px;
        }
      }
    }
  }

  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
  }
}
</style>
