<template>
  <div>
    <div v-if="!url" :class="$style.outlineBox" :style="styleObject">
      <div>
        <span
          :class="$style.outlineIcon"
          class="TC tc-icon-shexiangtoujinyong"
        ></span>
        <p>当前摄像头已离线，请稍后再试</p>
      </div>
    </div>
    <div v-else :class="$style.iframe" :style="styleObject">
      <video-view normal :accessToken="token" :url="url" />
    </div>
    <div :class="$style.titleBox">
      <div :class="$style.title">
        {{ result.projectName }}（楼栋：{{ result.buildingName }}）
      </div>
      <div :class="$style.subBox">
        <span :class="$style.time"
          >最近上线时间：{{ result.lastOnlineTime }}</span
        >
        <a :class="$style.button" target="_blank" :href="buildingLink"
          >楼栋详情&gt;&gt;</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { createModuleUrl } from '@/utils';
import VideoView from '@/components/video-view';

@Component({
  components: {
    VideoView,
  },
})
export default class Monitor extends Vue {
  @Prop({ type: String, default: '' }) url;
  @Prop({ type: String, default: '' }) token;
  @Prop({
    type: Object,
    default: () => ({
      height: '500px',
    }),
  })
  styleObject;
  @Prop({ type: Object }) result;

  get buildingLink() {
    return `${createModuleUrl('iot-max')}/iot/screen/projectBuilding/${
      this.result.projectId
    }?b=${this.result.buildId}&c=${this.result.cameraId}`;
  }
}
</script>

<style lang="less" module>
.outlineBox {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  .outlineIcon {
    font-size: 200px;
    color: #073b6a;
  }
  p {
    margin-top: 22px;
    font-size: 14px;
    color: #096c9a;
  }
}
.iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
}
.titleBox {
  padding: 0 20px;
  position: relative;
  .title {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 900;
  }
  .subBox {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .time,
  .button {
    line-height: 3;
  }
}
</style>
